<template>
  <mf-dialog v-model="dialog" max-width="600" persistent title="Copiar modelo de outro usuário">
    <v-container class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <v-autocomplete
            v-model="selected"
            chips
            label="Buscar usuário"
            :items.sync="usersList"
            :search-input.sync="search"
            dense
            placeholder="Email"
            outlined
          />
        </v-col>
        <v-col cols="auto"><v-checkbox v-model="copyTypes" class="mr-4" value="accounts" label="Clientes" /> </v-col>
        <v-col cols="auto">
          <v-checkbox v-model="copyTypes" value="units" label="Lojas" />
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="auto">
          <mf-button outlined label="Cancelar" @click="$emit('close')"></mf-button>
        </v-col>
        <v-col cols="auto">
          <mf-button :disabled="!selected || !copyTypes.length" color="primary" label="Copiar" @click="copy"></mf-button>
        </v-col>
      </v-row>
    </v-container>
  </mf-dialog>
</template>

<script>
import { QUERY_SEARCH_USERS, MUTATION_COPY_FROM_USER } from '@/modules/users/graphql'

export default {
  props: {
    dialog: Boolean,
    id: String,
    forcePush: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    usersList: [],
    selected: null,
    copyTypes: ['accounts']
  }),
  watch: {
    search(value) {
      if (value && value.trim() !== '') this.getUsers(value)
    }
  },
  methods: {
    async copy() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_COPY_FROM_USER,
          variables: {
            id: this.id,
            login: this.selected,
            copyTypes: this.copyTypes
          }
        })
        this.$emit('close')
        this.search = ''
        this.usersList = []
        this.forcePush = true
        this.$alert({
          alert_message: 'Copiado com sucesso...',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        setTimeout(() => {
          this.$router.push(`/usuarios`)
        }, 500)
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao copiar dados do usuário',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async getUsers(value) {
      setTimeout(async () => {
        const { data } = await this.$apollo.query({
          query: QUERY_SEARCH_USERS,
          variables: { param: value }
        })
        this.usersList = data.searchUser.map(user => user.login)
      }, 1500)
    }
  }
}
</script>
